import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SEO from "../components/SEO"

const TermsOfUse = (props) => (
  <Layout location={props.location}>
    <SEO title="Terms of Use" />
    <Heading title="Terms of Use" />
    <div className="row">
      <div className="col-12">
        <p>
          This website is owned by Solomon Motor Group and operated by DSS
          Digital. By accessing, browsing or using this website, you agree to
          the terms, conditions and disclaimers herein as amended from time to
          time.
        </p>
        <h3>Trademarks, Copyrights and Restrictions</h3>
        <p>
          All material on this site, including, but not limited to images, logos
          and illustrations, is protected by copyrights, trademarks, and other
          intellectual property rights which are owned and controlled by DSS
          Digital and its related companies. The content of www.dssdigital.net
          is provided for your personal, non-commercial use. Such material may
          not be copied, republished, reproduced, uploaded, modified,
          transmitted, posted, or distributed in any way without the consent of
          DSS Digital. The use of this website and these conditions is in
          accordance with the laws of Australia. Any legal action arising out of
          its use shall be brought and enforced under Australian laws. By using
          this site, you agree to submit to the jurisdiction of the courts of
          Australia and any legal action pursued by you shall be within the
          exclusive jurisdiction of the courts of Australia
        </p>
        <h3>Links to other sites</h3>
        <p>
          Solomon Motor Group is not responsible for the privacy practices or
          the content of other sites that are linked to
          www.solomonmotorgroup.com.au
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsOfUse
